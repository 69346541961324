<template>
    <div>
        <st-page
            :title="$t('INSTITUTION.PAGE_HEADER')"
        >
            <template #toolbar>
                <st-button
                    v-can:create="'organisations'"
                    variant="secondary"
                    :callback="addOrganisation"
                >
                    <i class="fa fa-plus"></i>
                    {{ $t('INSTITUTION.BUTTON.ADD') }}
                </st-button>
            </template>
            <organisation-list-filter ref="listFilter">
                <organisation-list-table/>
            </organisation-list-filter>
        </st-page>
        <add-institution
            ref="add-org"
            @submit="submitAddOrg"
        />
    </div>
</template>

<script>
    import OrganisationListFilter from '../components/institutions/OrganisationListFilter';
    import OrganisationListTable from '../components/institutions/OrganisationListTable';
    import AddInstitution from '@/modules/administrator/components/institutions/AddInstitutionModal.vue';

    export default {
        name: 'OrganisationList',
        components: {
            OrganisationListFilter,
            OrganisationListTable,
            AddInstitution
        },
        data() {
            return {
                showAddInstitution: false,
            };
        },
        methods: {
            addOrganisation() {
                this.$refs['add-org'].show();
            },
            submitAddOrg() {
                this.$refs.listFilter.refresh();
            }
        }
    }
</script>
