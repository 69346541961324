<template>
    <st-filters-pagination
        stateModule="administrator/orgList"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :moreFilters="false"
        :header="$t('INSTITUTION.LIST.TITLE')"
        :showFilters="isDisplay"
        :showPagination="isDisplay"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { OrganisationModel } from '@/modules/administrator/models/organisation-model';
    import Roles from '@/security/roles';
    const { fields } = OrganisationModel;

    const filterSchema = new FilterSchema([
        fields.name,
        fields.status,
        fields.is_test,
    ]);

    export default {
        name: 'OrganisationListFilter',
        data() {
            return {
                rules: filterSchema.rules(),
                model: {},
                currentPage: 1,
                isDisplay: true
            };
        },
        computed: {
            ...mapGetters({
               total: 'administrator/orgList/total',
               currentUserType: 'auth/currentUserType',
               accountDetails: 'auth/accountDetails',
            }),
            filters() {
                return filterSchema.fields;
            }
        },
        methods: {
            ...mapActions({
                doFetch: 'administrator/orgList/getOrganisations',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            doFilter(params) {
                this.params = params;
                if(this.currentUserType === Roles.values['admin']) {
                    this.isDisplay = false;
                    this.doFetch({payload: params, orgId: this.accountDetails.organisation_id});
                } else if (this.currentUserType === Roles.values['super_admin']){
                    this.isDisplay = true;
                    this.doFetch({payload: params});
                }
            },
        },

    };
</script>
